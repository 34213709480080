.contact-sales__loader-wrapper{
     background-color: rgba(255,255,255,0.7);
     position: fixed;
     top: 0;
     bottom: 0;
     left: 0;
     z-index: 100;
     height: 100vh;
     width: 100%;
     display: flex;
     justify-content: center;
     margin: auto;
     align-items: center;
}

@keyframes puffing {
     0% { opacity: 0; }
     50% { opacity: 1; }
     100% { opacity: 0; }
}
 
.contact-sales__loader-text {
     z-index: 1; 
     animation: puffing 1.5s infinite;
     font-family: 'PP Neue Montreal', sans-serif;
     font-size: 16px;
     font-weight: 450;
     color: #294254;
     margin-left: 8px;
     letter-spacing: 1px;
}
