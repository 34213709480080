.shops{
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    background-color: var(--heroGray);
}

.shops__page-container{
    max-width: 1320px;
    margin: auto;
    align-items: center;
    padding: 1rem 1.5rem;
}