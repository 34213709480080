.pay-in-store{
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    background-color: var(--heroGray);
}

.pay-in-store__page-container{
    max-width: 1320px;
    margin: auto;
    align-items: center;
    padding: 1rem 1.5rem;
}

/*START OF CSS FOR HERO SECTION -----------*/
.pay-in-store__hero-section-container{
    width: 100%;
    padding: 2rem 0;
}

.pay-in-store__hero-text-wrapper{
    padding-bottom: 3rem;
}

.pay-in-store__hero-logo-wrapper{
    display: flex;
    justify-content: center;
    width: 100%;
    margin: auto;
    align-items: center;
    padding-bottom: 1rem;
}

.pay-in-store__hero-logo{
    width: 8%;
    background-color: var(--white);
    padding: 1rem 1.25rem;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
    border-radius: 30px;
}

.pay-in-store__hero-heading-wrapper{
    padding: 0.5rem 0;
}

.pay-in-store__hero-heading{
    text-align: center;
    font-family: 'PP Neue Montreal', sans-serif;
    font-weight: 500;
    font-size: 75px; 
    letter-spacing: 0.5px;
    line-height: 80px;
    color: var(--brandBlack)
}

.pay-in-store__hero-subheading-wrapper{
    padding: 0.5rem 0;
}

.pay-in-store__hero-subheading{
    text-align: center;
    font-family: 'PP Neue Montreal', sans-serif;
    font-weight: 500;
    font-size: 31px; 
    letter-spacing: 0.5px;
    color: var(--brandGray);
}

.pay-in-store__hero-image-wrapper {
    width: 100%;
    height: 500px; /* Set the desired height of the visible area */
    overflow: hidden; /* Hides anything outside the set height */
    position: relative;
    border-radius: 20px;
}

.pay-in-store__hero-image {
    width: 100%;
    height: auto; /* Ensures the image maintains its original aspect ratio */
    position: absolute;
    top: -100px;
    image-rendering: auto;
}
/*END OF CSS FOR HERO SECTION -----------*/


/*START OF CSS FOR TAP TO PAY SECTION -----------*/
.pay-in-store__tap2pay-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 5rem;
    padding: 5rem 0;
}

.pay-in-store__tap2pay-text-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
}

.pay-in-store__tap2pay-topline-wrapper{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 0.75rem;
    margin: auto;
    align-items: center;
}

.pay-in-store__tap2pay-topline{
    font-family: 'PP Neue Montreal', sans-serif;
    font-weight: 500;
    font-size: 14px; 
    letter-spacing: 1.75px;
    color: var(--darkGray);
}

.pay-in-store__tap2pay-topline-coming-soon{
    font-family: 'PP Neue Montreal', sans-serif;
    font-weight: 400;
    font-size: 15px; 
    letter-spacing: 1px;
    color: var(--brandGray);
    background-color: #c7c8cf;
    padding: 8px 15px;
    border-radius: 30px;
}

.pay-in-store__tap2pay-heading-wrapper{
    width: 100%;
    padding: 1rem 0;
}

.pay-in-store__tap2pay-heading{
    font-family: 'PP Neue Montreal', sans-serif;
    font-weight: 500;
    font-size: 55px; 
    letter-spacing: 1px;
    color: var(--brandBlack);
    line-height: 58px;
}

.pay-in-store__tap2pay-subheading-wrapper{
    width: 100%;
    padding-top: 0.5rem;
}

.pay-in-store__tap2pay-subheading{
    font-family: 'PP Neue Montreal', sans-serif;
    font-weight: 400;
    font-size: 20px; 
    letter-spacing: 1px;
    color: var(--brandGray);
    line-height: 30px;
}

.pay-in-store__tap2pay-heading span{
    color: var(--brandRed)
}

.pay-in-store__tap2pay-image-wrapper {
    width: 100%;
    height: 700px; /* Desired height of the visible area */
    overflow: hidden;
    position: relative;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.pay-in-store__tap2pay-image {
    width: 100%;
    height: 100%; /* Stretch to fill the height of the wrapper */
    object-fit: cover; /* Maintains aspect ratio while filling the container */
    position: absolute;
    top: 50%; /* Center the image vertically */
    left: 50%; /* Center the image horizontally */
    transform: translate(-50%, -50%); /* Adjust position to truly center */
}

/*START OF CSS FOR SCAN TO PAY SECTION -----------*/
.pay-in-store__scan2pay-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 5rem;
    padding: 3rem 0;
}

.pay-in-store__scan2pay-text-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
}

.pay-in-store__scan2pay-topline-wrapper{
    width: 100%;
}

.pay-in-store__scan2pay-topline{
    font-family: 'PP Neue Montreal', sans-serif;
    font-weight: 500;
    font-size: 14px; 
    letter-spacing: 1.75px;
    color: var(--darkGray);
}

.pay-in-store__scan2pay-image-wrapper {
    width: 100%;
    height: 700px; /* Desired height of the visible area */
    overflow: hidden;
    position: relative;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.pay-in-store__scan2pay-image {
    width: 100%;
    height: 100%; /* Stretch to fill the height of the wrapper */
    object-fit: cover; /* Maintains aspect ratio while filling the container */
    position: absolute;
    top: 50%; /* Center the image vertically */
    left: 50%; /* Center the image horizontally */
    transform: translate(-50%, -50%); /* Adjust position to truly center */
}

.pay-in-store__scan2pay-heading-wrapper{
    width: 100%;
    padding: 1rem 0;
}

.pay-in-store__scan2pay-heading{
    font-family: 'PP Neue Montreal', sans-serif;
    font-weight: 500;
    font-size: 55px; 
    letter-spacing: 1px;
    color: var(--brandBlack);
    line-height: 58px;
}

.pay-in-store__scan2pay-heading span{
    color: var(--brandRed)
}

.pay-in-store__scan2pay-subheading-wrapper{
    width: 100%;
    padding-top: 0.5rem;
}

.pay-in-store__scan2pay-subheading{
    font-family: 'PP Neue Montreal', sans-serif;
    font-weight: 400;
    font-size: 20px; 
    letter-spacing: 1px;
    color: var(--brandGray);
    line-height: 30px;
}
/*END OF CSS FOR SCAN TO PAY SECTION -----------*/

/*START OF CSS FOR HOW TO PAY SECTION -----------*/
.pay-in-store__how2pay-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 5rem 0;
}

.pay-in-store__how2pay-heading-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
}

.pay-in-store__how2pay-topline-wrapper{
    width: 100%;
}

.pay-in-store__how2pay-topline{
    font-family: 'PP Neue Montreal', sans-serif;
    font-weight: 500;
    font-size: 14px; 
    letter-spacing: 1.25px;
    color: var(--darkGray);
    text-align: center;
}

.pay-in-store__how2pay-title-wrapper{
    width: 100%;
    padding: 1rem 0;
}

.pay-in-store__how2pay-title{
    font-family: 'PP Neue Montreal', sans-serif;
    font-weight: 500;
    font-size: 55px; 
    letter-spacing: 1px;
    color: var(--brandBlack);
    line-height: 58px;
    text-align: center;
}

.pay-in-store__how2pay-title span{
    color: var(--brandRed)
}

.pay-in-store__how2pay-subtitle-wrapper{
    width: 65%;
    padding-top: 0.5rem;
}

.pay-in-store__how2pay-subtitle{
    font-family: 'PP Neue Montreal', sans-serif;
    font-weight: 400;
    font-size: 20px; 
    letter-spacing: 1px;
    color: var(--brandGray);
    line-height: 30px;
    text-align: center;
}

.pay-in-store__how2pay-steps-wrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 5rem 0;
    background-color: transparent;
}

.pay-in-store__how2pay-steps-image-wrapper{
    width: 100%;
    padding-top: 8rem;
    padding-left: 7rem;
    padding-right: 7rem;
    background-color: #57768d;
    text-align: center;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}

.pay-in-store__how2pay-steps-presentation{
    width: 80%;
    height: 468px;
    margin: auto;
    align-items: center;
    background-color: var(--white);
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}

.pay-in-store__how2pay-steps-text-wrapper{
    width: 100%;
    padding: 7rem 5rem;
    background-color: #294254;
    text-align: center;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}

.pay-in-store__how2pay-steps-text{
    text-align: left;
    font-family: 'PP Neue Montreal', sans-serif;
    font-weight: 400;
    font-size: 17px; 
    letter-spacing: 1.25px;
    color: var(--white);
}

.pay-in-store__how2pay-steps-content{
    text-align: left;
}

.pay-in-store__how2pay-steps{
    padding-top: 1.75rem;
    font-family: 'PP Neue Montreal', sans-serif;
    font-weight: 400;
    font-size: 22px; 
    letter-spacing: 1px;
    color: #416279;
    line-height: 30px;
}