.footer{
     box-sizing: border-box;
     height: 100%;
     width: 100%;
     background-color: var(--white);
     padding-top: 1rem;
}

.footer__page-container{
     max-width: 1320px;
     margin: auto;
     align-items: center;
}

.footer__topbar-section{
     display: flex;
     justify-content: space-between;
     border-top: 0.1px solid #c5c3c3;
     padding: 2.5rem 0;
     gap: 3rem;
}

.footer__corporate-wrapper{
     width: 140%;
     display: flex;
     flex-direction: column;
}

.footer__corporate-logo{
     width: 170px;
}

.footer__corporate-address-wrapper{
     margin-top: 0.75rem;
     padding-right: 2rem;
}

.footer__bsp-disclosure{
     font-family: 'PP Neue Montreal', sans-serif;
     font-weight: 400;
     font-size: 15px;
     color: var(--brandBlue);
     letter-spacing: 0.75px;
}

.footer__bsp-concerns{
     font-family: 'PP Neue Montreal', sans-serif;
     font-weight: 400;
     font-size: 15px;
     color: var(--brandBlue);
     letter-spacing: 0.75px;
     padding-top: 1rem;
}

.footer__links-wrapper{
     padding-top: 1rem;
     width: 70%;
     display: flex;
     flex-direction: column;
}

.footer__links-label{
     font-family: 'PP Neue Montreal', sans-serif;
     font-weight: 450;
     font-size: 16px;
     color: var(--brandBlue);
     letter-spacing: 1px;
}

.footer__links-container{
     list-style: none;
     margin-top: 1rem;
}

.footer__link-item{
     padding: 0.25rem 0;
}

.footer__link{
     text-decoration: none;
     font-family: 'PP Neue Montreal', sans-serif;
     font-weight: 450;
     font-size: 15px;
     color: #8c91a1;
     letter-spacing: 0.75px;
}

.footer__link:hover{
     color: #ffffff;
}

/*START OF CSS FOR BOTTOM BAR SECTION -----------*/
.footer__bottomBar-section{
     display: flex;
     justify-content: space-between;
     border-top: 0.1px solid #CFD8E3;
     padding: 1rem 0 2rem 0;
     gap: 3rem;
     margin: auto;
     align-items: center;
}

.footer__copyright-wrapper{
     width: 60%;
}

.footer__policy-wrapper{
     width: 100%;
}

.footer__copyright{
     font-family: 'PP Neue Montreal', sans-serif;
     font-weight: 450;
     font-size: 15px;
     color: var(--brandBlue);
     letter-spacing: 0.75px;
}

.footer__policy-link-container{
     display: flex;
     justify-content: flex-start;
     gap: 2rem;
     list-style: none;
}

.footer__policy-link{
     font-family: 'PP Neue Montreal', sans-serif;
     font-weight: 450;
     font-size: 15px;
     color: #8c91a1;
     letter-spacing: 0.75px;
     text-decoration: none;
}

.footer__policy-link:hover{
     text-decoration: underline;
}

.footer__socials-wrapper {
     width: 100%;
     display: flex;
     justify-content: flex-end;
}
   
.footer__socials-wrapper a {
     display: flex;
     justify-content: center; 
     align-items: center;
     padding: 3px;
     margin: 5px; 
}
   
.footer__socials-link {
     width: 30px;
     height: 30px; 
}