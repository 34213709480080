.aboutUs {
     box-sizing: border-box;
     height: 100%;
     width: 100%;
     background-color: #202122;
}

.aboutUs__page-container{
     margin: auto;
     width: 100%;
     align-items: center;
}

/*START OF CSS FOR HERO SECTION -----------*/
.aboutUs__hero-section {
     width: 100%;
     display: flex;
     align-items: center;
     justify-content: center;
     background: 
          linear-gradient(to bottom, rgba(129, 171, 203, 0.4), rgba(233, 231, 232, 0.8)),
          url('../../../Assets/Images/about_hero_image.svg');
     background-size: cover; /* Ensures the image covers the entire area */
     background-position: center; /* Centers the image */
     background-repeat: no-repeat; /* Prevents the image from repeating */
}

.aboutUs__hero-section-container{
     max-width: 1320px;
     margin: auto;
     align-items: center;
     display: flex;
     flex-direction: column;
     margin: auto;
     align-items: center;
     padding: 4rem 0rem;
     text-align: center;
}

.aboutUs__hero-section-heading{
     width: 60%;
}

.aboutUs__hero-heading-text{
     font-family: 'PP Neue Montreal', sans-serif;
     font-weight: 500;
     letter-spacing: -0.2px;
     font-size: 60px;
     color: #284154;
     line-height: 80px;
}

.aboutUs__hero-heading-text span{
     font-family: 'Open Sans', sans-serif;
     font-weight: 700;
     letter-spacing: -2px;
}

.aboutUs__hero-subheading-text{
     font-family: 'PP Neue Montreal', sans-serif;
     font-weight: 400;
     font-size: 23px;
     letter-spacing: 0.75px;
     color:#284154;
     line-height: 1.6rem;
     padding-top: 1rem;
}

.aboutUs__hero-image-wrapper{
     width: 100%;
}

.aboutUs__hero-headline-btn-wrapper{
     padding-top: 4rem;
}

.aboutUs__hero-headline-btn{
     padding: 8px 23px;
     font-family: 'PP Neue Montreal', sans-serif;
     font-weight: 400;
     font-size: 16px;
     letter-spacing: 0.75px;
     color: #284154;
     border: 0.1px solid #284154;
     border-radius: 50px;
     background-color: transparent;
}
/*END OF CSS FOR HERO SECTION -----------*/

/*START OF CSS FOR STORY SECTION -----------*/
.aboutUs__story-section{
     background-color: rgba(0, 0, 0, 0.7);
     background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)), url('../../../Assets/Images/hero_image_bg.svg'); /* Adjust the path as needed */
     background-size: cover; /* Ensures the image covers the entire hero section */
     background-position: center; /* Centers the image */
     background-repeat: no-repeat; /* Prevents the image from repeating */
}

.aboutUs__story-section-container{
     max-width: 1320px;
     margin: auto;
     align-items: center;
     padding: 5rem 0.5rem;
}

.aboutUs__story-section-heading-wrapper{
     width: 53%;
     display: flex;
     flex-direction: column;
     margin: auto;
     align-items: center;
     padding-bottom: 2rem;
}

.aboutUs__story-heading{
     font-family: 'PP Neue Montreal', sans-serif;
     font-weight: 600;
     font-size: 50px;
     color: #ffffff;
     letter-spacing: 1px;
}

.aboutUs__story-subheading{
     font-family: 'PP Neue Montreal', sans-serif;
     font-weight: 400;
     font-size: 23px;
     text-align: center; 
     color: #e7e9ec;
     letter-spacing: 0.25px;
     margin-top: 0.75rem;
}

.aboutUs__story-subheading span{
     font-weight: 600;
     color: #ff5161;
     margin-right: 5px;
}

.about__top-story-wrapper{
     padding: 3rem 0;
     display: flex;
     justify-content: space-between;
     gap: 4rem;
     width: 100%;
}

.about__top-story-image-wrapper{
     width: 100%;
     background-color: #DEBEA9;
     border-radius: 7px;
     display: flex;
     justify-content: center;
     align-items: center;
}

.about__top-story-text-wrapper{
     width: 100%;
     padding: 4rem 0;
}

.about__top-story-text-heading{
     font-family: 'PP Neue Montreal', sans-serif;
     font-weight: 600;
     font-size: 50px;
     text-align: left; 
     letter-spacing: 0.5px;
     padding-bottom: 1rem;
     color: #ff5161;
}

.about__top-story-text-body-top{
     font-family: 'PP Neue Montreal', sans-serif;
     font-weight: 400;
     font-size: 18px;
     text-align: left; 
     letter-spacing: 0.75px;
     padding-bottom: 1rem;
     line-height: 1.7rem;
     color: #e7e9ec;
}

.about__top-story-text-body-bottom{
     font-family: 'PP Neue Montreal', sans-serif;
     font-weight: 400;
     font-size: 18px;
     text-align: left; 
     letter-spacing: 0.75px;
     line-height: 1.7rem;
     color: #e7e9ec;
}

.about__bottom-story-wrapper{
     padding: 3rem 0;
     display: flex;
     justify-content: space-between;
     gap: 4rem;
     width: 100%;
}

.about__bottom-story-text-wrapper{
     width: 100%;
     padding: 4rem 0;
}

.about__bottom-story-text-heading{
     font-family: 'PP Neue Montreal', sans-serif;
     font-weight: 600;
     font-size: 50px;
     text-align: left; 
     letter-spacing: 0.5px;
     padding-bottom: 1rem;
     color: #ff5161;
}

.about__bottom-story-text-body-top{
     font-family: 'PP Neue Montreal', sans-serif;
     font-weight: 400;
     font-size: 18px;
     text-align: left; 
     letter-spacing: 0.75px;
     padding-bottom: 1rem;
     line-height: 1.7rem;
     color: #e7e9ec;
}

.about__bottom-story-text-body-bottom{
     font-family: 'PP Neue Montreal', sans-serif;
     font-weight: 400;
     font-size: 18px;
     text-align: left; 
     letter-spacing: 0.75px;
     padding-bottom: 1rem;
     line-height: 1.6rem;
     color: #e7e9ec;
}

.about__bottom-story-image-wrapper{
     width: 100%;
     background-color: #000000;
     border-radius: 7px;
     display: flex;
     justify-content: center;
     align-items: center;
}

/*END OF CSS FOR STORY SECTION -----------*/


/*START OF CSS FOR MISSION SECTION -----------*/
.aboutUs__mission-section{
     background: 
          linear-gradient(to top, rgba(152, 193, 224, 0.8), rgba(233, 231, 232, 0.8)),
          url('../../../Assets/Images/mission_hero_bg.svg');
     background-size: cover;
     background-position: center;
     background-repeat: no-repeat;
     box-shadow: rgba(35, 68, 82, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.aboutUs__mission-section-container{
     max-width: 1320px;
     display: flex;
     justify-content: space-between;
     margin: auto;
     gap: 4rem;
     padding: 5rem 0.5rem;
}

.aboutUs__mission-text-wrapper{
     width: 100%;
}

.aboutUs__mission-headline{
     font-family: 'PP Neue Montreal', sans-serif;
     font-weight: 500;
     font-size: 18px;
     color: #b82142;
     letter-spacing: 0.90px;
     padding-bottom: 0.75rem;
}

.aboutUs__mission-statement{
     font-family: 'Open Sans', sans-serif;
     font-weight: 700;
     font-size: 50px;
     text-align: left; 
     color: #284154;
     letter-spacing: -2px;
     line-height: 3.5rem;
}

.aboutUs__profile-wrapper{
     width: 100%;
}

.aboutUs__profile-heading{
     font-family: 'Open Sans', sans-serif;
     font-weight: 700;
     font-size: 18px;
     color: #284154;
     padding-bottom: 0.75rem;
}

.aboutUs__profile-content-one{
     font-family: 'PP Neue Montreal', sans-serif;
     font-weight: 400;
     font-size: 19px;
     text-align: left; 
     letter-spacing: 0.75px;
     padding-bottom: 1rem;
     line-height: 1.6rem;
     color: #284154;
}

/*END OF CSS FOR MISSION SECTION -----------*/



/*START OF CSS FOR CAREER SECTION -----------*/
.aboutUs__career-section{
     padding: 3rem;
     background-color: var(--white);
}

.aboutUs__career-section-container{
     max-width: 1320px;
     padding: 2rem 1rem;
     margin: auto;
     align-items: center;
     background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)), url('../../../Assets/Images/hero_image_bg.svg'); /* Adjust the path as needed */
     background-size: cover; /* Ensures the image covers the entire hero section */
     background-position: center; /* Centers the image */
     background-repeat: no-repeat; /* Prevents the image from repeating */
     border-radius: 10px;
 
}

.aboutUs__career-section-wrapper{
     padding: 1.5rem 2.5rem;
     box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
     border-radius: 3px;
     display: flex;
     justify-content: space-between;
     margin: auto;
     align-items: center;
}

.aboutUs__career-text-wrapper{
     width: 100%;
}

.aboutUs__career-text{
     font-family: 'PP Neue Montreal', sans-serif;
     font-weight: 400;
     font-size: 20px; 
     letter-spacing: 1.25px;
     color: var(--white);
}

.aboutUs__career-button-wrapper{
     width: 100%;
     display: flex;
     justify-content: flex-end;
}

.aboutUs__career-button{
     padding: 10px 25px;
     font-family: 'PP Neue Montreal', sans-serif;
     font-weight: 400;
     font-size: 16px; 
     letter-spacing: 1px;
     background-color: #284154;
     border: 0.1px solid #284154;
     border-radius: 3px;
     color: #ffffff;
     cursor: pointer;
}