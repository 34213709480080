.homepage{
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    background-color: #202122;
}

.homepage__page-container{
    width: 100%;
    margin: auto;
    align-items: center;
}


/* ----------------------------------------
    THIS IS THE CSS FOR THE HERO SECTION
    background-color: #ebf5fd;
------------------------------------------*/
.hero-section{
    margin: auto;
    width: 100%;
    align-items: center;
    background-color: var(--white);
    border-bottom: 0.1px solid var(--black);
}

.hero-section__page-container{
    max-width: 1320px;
    margin: auto;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
    gap: 3rem;
}
.hero-section__text-wrapper{
    width: 100%;
}

.hero-section__heading-title{
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    letter-spacing: -3px;
    font-size: 70px;
    color: var(--brandBlack);
    line-height: 75px;
}

.hero-section__heading-title span{
    color: var(--brandRed);
}


.hero-section__subheading{
    padding: 2rem 0;
    width: 93%;
}

.hero-section__subheading-text{
    font-size: 19px;
    font-weight: 400;
    letter-spacing: 0.75px;
    color: var(--brandBlack);
    line-height: 30px;
}


.hero-section__button-wrapper{
    padding: 1rem 0;
    display: flex;
    gap: 20px;
}

.hero-section__works-btn{
    padding: 15px 45px;
    border-radius: 50px;
    border: none;
    background-color: var(--brandBlue);
    cursor: pointer;
    align-items: center;
    font-family: 'PP Neue Montreal', sans-serif;
    font-size: 17px;
    font-weight: 500;
    color: var(--white);
    letter-spacing: 0.75px;
}

.hero-section__works-btn:hover{
    background-color: #ff5161;
    color: #ffffff;
}


.hero-section__image-wrapper{
    width: 85%;
}

.hero-section__image-wrapper img{
    width: 100%;
}
/* ----------------------------------------
    END OF THE CSS FOR THE HERO SECTION
------------------------------------------*/


/* --------------------------------------------
    START OF THE CSS FOR THE FEATURES SECTION
----------------------------------------------*/

.features-section{
    margin: auto;
    width: 100%;
    align-items: center;
    background-color: var(--lightWhite);
}

.features-section__container{
    max-width: 1320px;
    margin: auto;
    align-items: center;
    padding: 5rem 0;
}

.features-section__background-wrapper{
    margin: auto;
    align-items: center;
    border-radius: 10px;
}

.features-section__header{
    margin: auto;
    align-items: center;
}

.features-section__header-title{
    text-align: left;
    font-family: 'PP Neue Montreal', sans-serif;
    font-size: 60px;
    font-weight: 530;
    color: #284154;
}

.features-section__header-subtitle{
    text-align: left;
    font-family: 'PP Neue Montreal', sans-serif;
    font-size: 32px;
    font-weight: 450;
    color: #293b47;
}

.features-section__feature-wrapper{
    display: flex;
    justify-content: space-between;
    gap: 7rem;
    padding: 5rem 0;
}

.features-section__feature-text-wrapper{
    width: 100%;
    margin: auto;
    align-items: center;
}

.features-section__feature-heading{
    font-family: 'PP Neue Montreal', sans-serif;
    font-size: 45px;
    font-weight: 530;
    color: #284154;
    padding-bottom: 1rem;
    line-height: 50px;
}

.features-section__feature-subheading{
    font-family: 'PP Neue Montreal', sans-serif;
    font-size: 22px;
    font-weight: 450;
    color: #405c70;
    padding-bottom: 1rem;
}

.features-section__feature-item-wrapper{
    margin-top: 15px;
}

.features-section__feature-item {
    display: flex;
    justify-content: space-between;
    margin: auto;
    align-items: center;
    gap: 20px;
    padding: 0.75rem 0;
}

.features-section__feature-icon-container{
    background-color: transparent;
    padding: 5px 0;
}

.features-section__feature-icon{
    width: 100%;
    height: 100%;
    color: #284153;
    font-size: 23px;
    padding: 3px;
    border-radius: 50px;
    background-color: #09D11C;
    border: 0.1px solid #09D11C;
    position: relative;
    bottom: 0.48rem;
}


.features-section__feature-text{
    font-family: 'PP Neue Montreal', sans-serif;
    font-size: 18px;
    font-weight: 430;
    color: #3d5a6e;
    text-align: left;
}

.features-section__feature-image-wrapper{
    width: 100%;
    background-color: #F7F7F7;
}

.features-section__feature-image-wrapper img{
    width: 100%;
}
/* --------------------------------------------
    END OF THE CSS FOR THE FEATURE SECTION
----------------------------------------------*/




/* -------------------------------
    CSS CODE FOR MOBILE DEVICES 
---------------------------------*/
@media only screen and (max-width: 767px) {

    /* --------------------------------------------
        START OF THE CSS FOR THE PROBLEM SECTION
    ----------------------------------------------*/
    .hero-section{
        padding: 1.5rem 1rem;
    }
    .hero-section__page-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .hero-section__heading{
        background-color: transparent;
    }
    .hero-section__text-wrapper{
        justify-content: center;
    }
    .hero-section__heading-title{
        text-align: center;
        font-size: 43px;
        font-weight: 530;
        line-height: 28px;
    }
    .hero-section__heading-subtitle{
        font-size: 33px;
        text-align: center;
        padding-top: 5px;
    }
    .hero-section__subheading{
        text-align: center;
    }

    .hero-section__button-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 15px;
    }
    .hero-section__waitlist-btn{
        width: 100%;
    }
    .hero-section__demo-btn{
        width: 100%;
    }
    .hero-section__image-wrapper{
        width: 100%;
        height: auto;
    }
    .hero-section__image-wrapper img{
        width: 100%;
        position: none;
        bottom: 0;
        right: 0;
    }
    /* --------------------------------------------
        END OF THE CSS FOR THE PROBLEM SECTION
    ----------------------------------------------*/


    /* --------------------------------------------
        END OF THE CSS FOR THE PROBLEM SECTION
    ----------------------------------------------*/
    .features-section{
        background-color: transparent;
    }
    .features-section__container{
        width: 100%;
        padding: 0.5rem;
    }
    .features-section__background-wrapper{
        margin: auto;
        align-items: center;
        padding: 0rem 0.75rem;
        border-radius: 10px;
    }
    .features-section__feature-wrapper{
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 50px 0;
    }
    .features-section__feature-icon{
        font-size: 50px;
    }
    .features-section__feature-text-wrapper{
        width: 100%;

    }
    .features-section__feature-image-wrapper{
        width: 100%;
    }
    .features-section__feature-image-wrapper img{
        width: 100%;
        position: none;
        left: 0px;
    }
}

