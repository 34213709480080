.contact-sales{
     box-sizing: border-box;
     height: 100%;
     width: 100%;
     display: flex;
     flex-direction: column;
}

.contact-sales__sales-container{
     max-width: 1320px;
     margin: auto;
     align-items: center;
     padding: 3rem 1.5rem;
}
.contact-sales__sales-form-wrapper{
     display: flex;
     justify-content: space-between;
     gap: 3rem;
}
.contact-sales__text-container{
     width: 100%;
}

.contact-sales__heading-wrapper{
     padding-bottom: 1rem;
}

.contact-sales__heading{
     font-family: 'PP Neue Montreal', sans-serif;
     font-weight: 530;
     font-size: 50px;
     letter-spacing: 0.5px;
     color: #284154;
}

.contact-sales__subheading{
     font-family: 'PP Neue Montreal', sans-serif;
     font-size: 18px;
     font-weight: 450;
     color: #405c70;
     padding-top: 5px;
     line-height: 25px;
}

.contact-sales__partners-wrapper{
     padding: 2rem 0;
}

.contact-sales__partners-heading-wrapper{
     padding-bottom: 0.5rem;
}

.contact-sales__partners-heading{
     font-family: 'PP Neue Montreal', sans-serif;
     font-size: 18px;
     font-weight: 450;
     color: #405c70;
}

.contact-sales__partners-banks-group1{
     display: flex;
     justify-content: flex-start;
     margin: auto;
     align-items: center;
     gap: 20px;
}

.contact-sales__bank-wrapper{
     width: 25%;
}

.contact-sales__bank-logo{
     height: 10%;
}

.contact-sales__form-container{
     width: 100%;
     background-color: #F7F7F7;
     padding: 3rem;
     border-radius: 0.25rem;
}

.contact-sales__form{
     background-color: transparent;
     display: flex;
     flex-direction: column;
     gap: 1rem;
}

.contact-sales__form-input-flex{
     display: flex;
     justify-content: flex-start;
     align-items: left;
     gap: 3rem;
}

.contact-sales__form-input-wrapper{
     width: 100%;
     height: 90px;
}

.contact-sales__input-label{
     font-family: 'PP Neue Montreal', sans-serif;
     font-size: 15px;
     font-weight: 530;
     color: #4a6981;
     padding-bottom: 0.50rem;
     text-indent: 1px;
}

.contact-sales__input{
     width: 100%;
     height: 2rem;
     padding: 1.25rem 1rem;
     border: 0.10rem solid #6285a0;
     border-radius: 0.15rem;
     font-family: 'PP Neue Montreal', sans-serif;
     font-size: 16px;
     font-weight: 450;
     color: #4d708a;
     outline: none;
}

.contact-sales__input::placeholder{
     color: #a9adb1;
     font-family: 'PP Neue Montreal', sans-serif;
     font-size: 15px;
     font-weight: 450;
}

.contact-sales__input:focus{
     border-color: #284154;
}

.contact-sales__input-error{
     border-color: #B82142;
}

.contact-sales__input-error-message{
     padding-top: 0.30rem;
     display: flex;
     justify-content: flex-start;
     margin: auto;
     align-items: center;
     font-family: 'PP Neue Montreal', sans-serif;
     font-size: 14px;
     font-weight: 450;
     letter-spacing: 0.25px;
     color: #e73c3c;
     padding-left: 0.25rem;
}

.contact-sales__input-error-icon{
     font-size: 14px;
     margin-right: 0.15rem;
}

.contact-sales__submission-wrapper{
     padding: 0.25rem 0;
}

.contact-sales__privacy-policy{
     padding: 0.5rem 0;
}

.contact-sales__privacy-policy-text{
     font-family: 'PP Neue Montreal', sans-serif;
     font-size: 16px;
     font-weight: 450;
     color: #284154;
}

.contact-sales__policy-link{
     font-family: 'PP Neue Montreal', sans-serif;
     font-size: 16px;
     font-weight: 450;
     color: #3e3ea7;
     text-decoration: none;
}
.contact-sales__policy-link:hover{
     text-decoration: underline;
}
.contact-sales__button-wrapper{
     padding-top: 1.25rem;
}

.contact-sales__submit-button{
     padding: 0.60rem 1.25rem;
     font-family: 'PP Neue Montreal', sans-serif;
     font-size: 16px;
     font-weight: 450;
     letter-spacing: 0.50px;
     color: #ffffff;
     background-color: #1F4168;
     border: 0.1px solid #1F4168;
     border-radius: 0.15rem;
     cursor: pointer;
}

.contact-sales__submit-button:hover{
     border: 0.1px solid #4470a3;
     background-color: #4470a3;
}

.contact-sales__support-container{
     background-color: #284154;
     margin: 0 1rem;
     border-radius: 0.50rem;
}

.contact-sales__contact-support-wrapper{
     max-width: 1320px;
     margin: auto;
     align-items: center;
     padding: 4rem 0.50rem;
}

.contact-sales__contact-support-top-statement-wrapper{
     padding-bottom: 0.50rem;
}

.contact-sales__contact-support-top-statement{
     text-align: center;
     font-family: 'PP Neue Montreal', sans-serif;
     font-size: 20px;
     font-weight: 450;
     letter-spacing: 1px;
     color: #ffffff;
}

.contact-sales__contact-support-heading-wrapper{
     padding: 0.50rem 0;
}

.contact-sales__contact-support-heading{
     text-align: center;
     font-family: 'PP Neue Montreal', sans-serif;
     font-size: 50px;
     font-weight: 600;
     letter-spacing: 1px;
     color: #ffffff;
}

.contact-sales__contact-support-subheading{
     padding-top: 0.50rem;
     text-align: center;
     font-family: 'PP Neue Montreal', sans-serif;
     font-size: 20px;
     font-weight: 430;
     letter-spacing: 1px;
     color: #ffffff;
}

.contact-sales__contact-support-subheading-mobile-wrapper{
     display: none;
}

.contact-sales__contact-support-button-wrapper{
     padding-top: 3rem;
     display: flex;
     justify-content: center;
     z-index: 1;
}

.contact-sales__contact-support-button{
     padding: 0.75rem 1rem;
     border-radius: 0.5rem;
     border: 0.1px solid #B82142;
     background-color: #B82142;
     color: #ffffff;
     outline: none;
     font-family: 'PP Neue Montreal', sans-serif;
     font-size: 18px;
     font-weight: 430;
     letter-spacing: 1.25px;
}

.contact-sales__contact-support-button:hover{
     border: 0.1px solid #fc5478;
     background-color: #fc5478;
     color: #ffffff;
     cursor: pointer;
}


.contact-sales__success-msg-container{
     max-width: 1320px;
     display: flex;
     flex-direction: column;
     justify-content: center;
     margin: auto;
     align-items: center;
     padding: 3rem 2.5rem;
     text-align: center;
     height: 68vh;
}

.contact-sales__success-msg-icon{
     font-size: 70px;
     color: #14A34D;
     padding-bottom: 0.75rem;
}

.contact-sales__success-msg-heading{
     font-family: 'PP Neue Montreal', sans-serif;
     font-size: 40px;
     font-weight: 500;
     color: #284154;
}

.contact-sales__success-msg-wrapper{
     padding: 1rem 0;
}

.contact-sales__success-msg-wrapper-mobile{
     display: none;
}

.contact-sales__success-msg{
     font-family: 'PP Neue Montreal', sans-serif;
     font-size: 19px;
     font-weight: 400;
     color: #284154;
     letter-spacing: 0.50px;
}

.contact-sales__success-msg-button-wrapper{
     padding: 2rem 0;
}

.contact-sales__success-msg-button{
     width: 145px;
     padding: 10px;
     font-family: 'PP Neue Montreal', sans-serif;
     font-size: 16px;
     font-weight: 400;
     border: 0.1px solid #284154;
     background-color: #284154;
     color: #ffffff;
     border-radius: 3px;
     letter-spacing: 0.50px;
}

.contact-sales__success-msg-button:hover{
     border: 0.1px solid #39576e;
     background-color: #39576e;
     cursor: pointer;
}

.contact-sales__error-message{
     width: 100%;
     height: 68vh;
     background-color: #ffffff;
     z-index: 20;
     text-align: center;
     display: flex;
     justify-content: center;
     margin: auto;
     align-items: center;
}

.contact-sales__error-message-container{
     display: flex;
     flex-direction: column;
     justify-content: center;
     margin: auto;
     align-items: center;
     width: 100%;
     padding: 30px 0;
}
 
.contact-sales__error-message-icon{
     color: #C04A46;
     font-size: 50px;
}
 
.contact-sales__error-msg{
     font-family: 'PP Neue Montreal', sans-serif;
     font-size: 19px;
     font-weight: 400;
     color: #32353d;
     padding-top: 10px;
}
 
.contact-sales__error-action{
     font-family: 'PP Neue Montreal', sans-serif;
     font-size: 19px;
     font-weight: 400;
     color: #32353d;
}
 
.contact-sales__error-code{
     font-family: 'PP Neue Montreal', sans-serif;
     font-size: 18px;
     font-weight: 500;
     color: #1c1e24;
     padding-top: 20px;
}


/* -------------------------------
    CSS CODE FOR MOBILE DEVICES 
---------------------------------*/
@media only screen and (max-width: 767px) {
     .contact-sales__sales-container{
          padding: 1.5rem 1rem;
     }
     .contact-sales__sales-form-wrapper{
          display: flex;
          flex-direction: column;
          gap: 2rem;
     }
     .contact-sales__heading-wrapper{
          padding: 0 0.5rem 0rem;
     }
     .contact-sales__heading{
          font-family: 'PP Neue Montreal', sans-serif;
          font-weight: 530;
          font-size: 45px;
          letter-spacing: -0.5px;
          color: #284154;
          text-align: center;
     }
     .contact-sales__subheading{
          font-family: 'PP Neue Montreal', sans-serif;
          font-size: 18px;
          font-weight: 450;
          color: #405c70;
          padding-top: 5px;
          line-height: 25px;
          text-align: center;
     }
     .contact-sales__partners-wrapper{
          display: none;
     }
     .contact-sales__form-container{
          padding: 1.5rem;
     }
     .contact-sales__form{
          background-color: transparent;
          display: flex;
          flex-direction: column;
          gap: 2em;
     }
     .contact-sales__form-input-flex{
          display: flex;
          flex-direction: column;
          align-items: left;
          gap: 2rem;
     }
     .contact-sales__input-label{
          font-family: 'PP Neue Montreal', sans-serif;
          font-size: 16px;
          font-weight: 530;
          color: #4a6981;
          padding-bottom: 0.50rem;
     }
     .contact-sales__input{
          width: 100%;
          height: 3rem;
          padding: 1.25rem 1rem;
          border: 0.10rem solid #6285a0;
          border-radius: 0.15rem;
          font-family: 'PP Neue Montreal', sans-serif;
          font-size: 16px;
          font-weight: 450;
          color: #4d708a;
          outline: none;
     }
     .contact-sales__input::placeholder{
          color: #a9adb1;
          font-family: 'PP Neue Montreal', sans-serif;
          font-size: 16px;
          font-weight: 450;
     }
     .contact-sales__input-error{
          border-color: #B82142;
     }
     
     .contact-sales__input-error-message{
          font-size: 15px;
     }
     .contact-sales__submission-wrapper{
          padding: 0rem;
     }
     .contact-sales__privacy-policy-text{
          font-size: 17px;
     }
     .contact-sales__policy-link{
          font-size: 17px;
     }
     .contact-sales__contact-support-wrapper{
          padding: 2.25rem 1.25rem;
     }
     .contact-sales__contact-support-top-statement{
          font-size: 17px;
          letter-spacing: 0.5px;
     }
     .contact-sales__contact-support-heading{
          font-size: 43px;
          letter-spacing: 1px;
          font-weight: 600;
          line-height: 38px;
     }
     .contact-sales__contact-support-heading-wrapper{
          padding-bottom: 1rem;
     }
     
     .contact-sales__contact-support-subheading{
          display: none;
     }
     .contact-sales__contact-support-subheading-mobile-wrapper{
          display: block;
          padding: 1rem 0rem 0rem;
     }
     .contact-sales__contact-support-subheading-mobile{
          font-size: 20px;
          font-weight: 430;
          letter-spacing: 1px;
          text-align: center;
          font-family: 'PP Neue Montreal', sans-serif;
          color: #ffffff;
          line-height: 23px;
     }
     .contact-sales__contact-support-button-wrapper{
          padding-top: 2rem;
     }
     .contact-sales__success-msg-button{
          width: 170px;
          font-size: 18px;
     }
     .contact-sales__success-msg-wrapper{
          display: none;
     }
     .contact-sales__success-msg-heading{
          font-size: 35px;
     }
     .contact-sales__success-msg-container{
          padding: 3rem 1.75rem;
     }
     .contact-sales__success-msg-wrapper-mobile{
          display: flex;
     }
     .contact-sales__success-msg-mobile{
          font-family: 'PP Neue Montreal', sans-serif;
          font-size: 17px;
          font-weight: 400;
          color: #284154;
          letter-spacing: 0.50px;
     }
}