
.customers-section{
    background-color: transparent;
}

.customers-section .section-container{
    max-width: 1280px;
    margin: auto;
    align-items: center;
    padding: 1rem 1rem 3rem;
}

.customers-section .section-container .header{
    margin: auto;
    align-items: center;
    padding: 50px 0 10px;
}

.customers-section .section-container .header .title{
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 55px;
    font-weight: 700;
    color: #363636;
}

.customers-section .section-container .header .subtitle{
    text-align: center;
    font-family: 'Figtree', sans-serif;
    font-size: 28px;
    font-weight: 500;
    color: #363636;
}

.customers-section .section-container .content-area{
    display: flex;
    justify-content: space-between;
    padding: 3rem 2rem 0;
    gap: 25px;
}


.customers-section .section-container .content-area .content-item{
    margin: auto;
    align-items: center;
    padding: 40px 22px;
}


.customers-section .section-container .content-area .content-item .caption-title{
    font-family: 'Figtree', sans-serif;
    font-size: 30px;
    font-weight: 600;
    color: #363636;
    text-align: center;
    padding-bottom: 10px;
}

.customers-section .section-container .content-area .content-item .image-placeholder{
    display: flex;
    justify-content: center;
    align-items: center;
}

.customers-section .section-container .content-area .content-item .caption-details{
    color: #363636;
    margin-top: 15px;
    font-weight: 400;
    font-size: 19px;
    font-family: 'Figtree', sans-serif;
}


.customers-section .section-container .content-area .content-item .image-placeholder img{
    width: 500px;
}



.customers-section .section-container .content-area .content-item  .learn-btn{
    margin-top: 2rem;
    padding: 8px;
    background-color: var(--btnColor);
    width: 110px;
    border-radius: 3px;
    text-align: center;
}

.customers-section .section-container .content-area .content-item  .learn-btn .learn-link{
    text-decoration: none;
    color: var(--white);
    font-weight: 300;
    font-size: 16px;
}