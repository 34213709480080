.pay-online{
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    background-color: var(--heroGray);
}

.pay-online__page-container{
    max-width: 1320px;
    margin: auto;
    align-items: center;
    padding: 1rem 1.5rem;
}

/*START OF CSS FOR HERO SECTION -----------*/
.pay-online__hero-section-container{
    width: 100%;
    padding: 2rem 0;
}

.pay-online__hero-text-wrapper{
    padding-bottom: 3rem;
}

.pay-online__hero-logo-wrapper{
    display: flex;
    justify-content: center;
    width: 100%;
    margin: auto;
    align-items: center;
    padding-bottom: 1rem;
}

.pay-online__hero-logo{
    width: 8%;
    background-color: var(--white);
    padding: 1rem 1.25rem;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
    border-radius: 30px;
}

.pay-online__hero-heading-wrapper{
    padding: 0.5rem 0;
}

.pay-online__hero-heading{
    text-align: center;
    font-family: 'PP Neue Montreal', sans-serif;
    font-weight: 500;
    font-size: 75px; 
    letter-spacing: 0.5px;
    line-height: 80px;
    color: var(--brandBlack)
}

.pay-online__hero-subheading-wrapper{
    padding: 0.5rem 0;
}

.pay-online__hero-subheading{
    text-align: center;
    font-family: 'PP Neue Montreal', sans-serif;
    font-weight: 500;
    font-size: 31px; 
    letter-spacing: 0.5px;
    color: var(--brandGray);
}

.pay-online__hero-image-wrapper {
    width: 100%;
    height: 500px; /* Set the desired height of the visible area */
    overflow: hidden; /* Hides anything outside the set height */
    position: relative;
    border-radius: 20px;
}

.pay-online__hero-image {
    width: 100%;
    height: auto; /* Ensures the image maintains its original aspect ratio */
    position: absolute;
    top: -125px;
    image-rendering: auto;
}
/*END OF CSS FOR HERO SECTION -----------*/

/*START OF CSS FOR HERO SECTION -----------*/
.pay-online__link-section-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 5rem;
    padding: 5rem 0;
}

.pay-online__link-text-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
}

.pay-online__link-topline-wrapper{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 0.75rem;
    margin: auto;
    align-items: center;
}

.pay-online__link-topline{
    font-family: 'PP Neue Montreal', sans-serif;
    font-weight: 500;
    font-size: 14px; 
    letter-spacing: 1.75px;
    color: var(--darkGray);
}

.pay-online__link-heading-wrapper{
    width: 100%;
    padding: 1rem 0;
}

.pay-online__link-heading{
    font-family: 'PP Neue Montreal', sans-serif;
    font-weight: 500;
    font-size: 55px; 
    letter-spacing: 0.75px;
    color: var(--brandBlack);
    line-height: 58px;
}

.pay-online__link-heading span{
    color: var(--brandRed)
}

.pay-online__link-subheading-wrapper{
    width: 100%;
    padding-top: 0.5rem;
}

.pay-online__link-subheading{
    font-family: 'PP Neue Montreal', sans-serif;
    font-weight: 400;
    font-size: 20px; 
    letter-spacing: 1px;
    color: var(--brandGray);
    line-height: 30px;
}

.pay-online__link-image-wrapper{
    width: 100%;
}


.pay-online__link-image{
    width: 100%;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}