.main{
    box-sizing: border-box;
    height: 100%;
    width: 100%;
}

.main__container{
    width: 100%;
    margin: auto;
    align-items: center;
}

.main__header{
    margin: auto;
    align-items: center;
    background-color: #fff;
    top: 0;
    position: sticky; 
    position: -webkit-sticky;
    z-index: 80;
}

/* -------------------------------------------
    THIS IS THE CSS FOR THE NAVBAR SECTION
---------------------------------------------*/
.navbar {
    width: 100%;
    padding: 0.75rem 0;
    background-color: var(--white);
}

.navbar__container {
    max-width: 1320px;
    display: flex;
    justify-content: space-between;
    margin: auto;
    align-items: center;
}

.navbar__mobile-wrapper{
    display: flex;
    justify-content: flex-start;
    width: 20%;
}

/* START OF LOGO SECTION*/
.navbar__logo-wrapper {
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    width: 80%;
}

.navbar__logo-wrapper img{
    width: 100%;
}

/* START OF MOBILE MENU WRAPPER*/
.navbar__mobile-menu-wrapper{
    display: none;
}
/* END OF MOBILE MENU WRAPPER*/

.navbar__menulinks-wrapper{
    width: 120%;
    display: flex;
    justify-content: flex-start;
}

/* START OF MENU SECTION*/
.navbar__menu-wrapper {
    width: 30%;
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin: auto;
    align-items: center;
}

.navbar__menu-item{
    position: relative;
    display: flex;
    justify-content: flex-start;
    margin: auto;
    align-items: center;
}


.navbar__menu-links{
    text-decoration: none;
    color: var(--brandBlue);
    font-family: 'PP Neue Montreal', sans-serif;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 0.75px;
    border-bottom: 1.75px solid transparent;
    padding: 5px;
}

.navbar__menu-icon{
    font-size: 10px;
    transform: scale(1.5);
    color: #ff5161;
    transition: transform 0.5s ease, color 0.5s ease;
    transform-origin: center;
    position: relative;
    top: 1px;
}

.navbar__menu-item-dropdown{
    margin-top: 0.5rem;
    list-style: none;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    position: absolute;
    left: -30px;
    width: 350px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 30px;
    border-radius: 0.15rem;
    overflow: hidden;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out, transform 0.5s ease-in-out;
    opacity: 0;
    visibility: hidden;
}

.navbar__menu-item:hover .navbar__menu-item-dropdown{
    opacity: 1;
    visibility: visible;
    transform: translateY(0%);
}

.navbar__menu-item:hover .navbar__menu-icon {
    transform: scaleY(-1) scale(1.5);
    color: #ff5161;
    font-size: 10px;
}

.navbar__menu-item:hover .navbar__menu-links {
    transition: 0.1s all ease-out;
    cursor: pointer;
    color: #8c91a1;
}

.navbar__menu-links-active{
    transition: 0.1s all ease-out;
    cursor: pointer;
    text-decoration: none;
    font-family: 'PP Neue Montreal', sans-serif;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 0.75px;
    padding: 5px;
    color: var(--brandBlue);
    border-bottom: 1.75px solid var(--brandRed);
}

/* END OF MENU SECTION*/


/* START OF NAVLINK SECTION*/
.navbar__navlinks-wrapper{
    width: 100%;
    list-style: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 2.5rem;;
}
.navbar__navlinks-item:nth-child(1){
    border: none;
}
.navbar__navlinks-item:nth-child(2){
    border-radius: 25px;
    border: none;
    padding: 10px 20px;
    background-color: #ff5161;
}
.navbar__navlinks-item:nth-child(2):hover,
.navbar__navlink-waitlist:hover{
    border: none;
    background-color: #4470a3;
    color: #ffffff;
}

.navbar__navlink-business{
    text-decoration: none;
    color: var(--brandBlue);
    font-family: 'PP Neue Montreal', sans-serif;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.75px;
}
.navbar__navlink-waitlist{
    text-decoration: none;
    color: #ffffff;
    font-family: 'PP Neue Montreal', sans-serif;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
}
/* END OF NAVLINK SECTION*/



/*CSS FOR MOBILE SCREENS*/
@media only screen and (max-width: 767px){
    .main__header{
        margin: auto;
        align-items: center;
        top: 0;
        position: sticky; 
        position: -webkit-sticky;
        z-index: 1000;
    }
    .navbar{
        padding: 0 0.25rem;
    }
    .navbar__container{
        position: relative;
        flex-direction: column;
        align-items: start;
    }
    .navbar__mobile-wrapper{
        display: flex;
        justify-content: space-between;
        margin: auto;
        align-items: center;
        position: relative;
        width: 98%;
        border-radius: 0.25rem;
        z-index: 20;
        background-color: var(--white);
        padding: 0.25rem 0;
    }
    .navbar__logo-wrapper{
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }
    .navbar__logo-wrapper img{
        width: 80%;
        display: flex;
        justify-content: flex-start;
    }
    .navbar__mobile-menu-wrapper{
        display: flex;
        justify-content: flex-end;
        height: 2rem;
        margin: auto;
        align-items: center;
        width: 100%;
        z-index: 100;
    }
    .navbar__mobile-menu-icon{
        font-size: 40px;
        background-color: #f0f0f0;
        border-radius: 50px;
        padding: 0.5rem;
    }
    .navbar__menulinks-wrapper{
        display: block;
        gap: 0rem;
        height: 100vh;
        background-color: #ffffff;
        overflow: hidden;
        transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out, transform 0.5s ease-in-out;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 10;
        padding: 0.5rem 0 1rem 0;
        width: 100%;
        transform: translateY(-25%);
        margin: auto;
    }
    .navbar__menulinks-wrapper.showMenuLinksWrapper{
        opacity: 1;
        visibility: visible;
        transform: translateY(0%);
    }
    .navbar__menu-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 0rem;
        padding: 0rem 1rem 1rem 1rem;
        background-color: #ffffff;
        width: 100%;
        border-top: 0.05rem solid #aaa9a9;
    }
    .navbar__menu-item{
        width: 100%;
        padding: 0.5rem 0.25rem;
    }
    .navbar__menu-item-text-wrapper{
        justify-content: space-between;

    }
    .navbar__menu-item-dropdown{
        width: 100%;
        position: relative;
        padding: 1rem 2rem;
        background-color: transparent;
    }
    .navbar__navlinks-wrapper{
        display: flex;
        justify-content: center;
        margin: auto;
        align-items: center;
        position: relative;
        top: 5rem;
        width: 80%;
        gap: 20px;
    }
    .navbar__navlinks-item:nth-child(1){
        border: 0.1px solid #1F4168;
        padding: 0.75rem 1.5rem;
        font-family: 'Figtree', sans-serif;
        font-size: 18px;
        font-weight: 500;
        background-color: #ffffff;
        color: #1F4168;
    }
    .navbar__navlinks-item:nth-child(2){
        border: 0.1px solid #1F4168;
        padding: 0.75rem 1.5rem;
        font-family: 'Figtree', sans-serif;
        font-size: 18px;
        font-weight: 500;
        background-color: #1F4168;
        color: #ffffff;
    }
}  