.waitlist{
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    background-color: #181818;
}

.waitlist__page-container{
    display: flex;
    justify-content: space-around;
    max-width: 1320px;
    margin: auto;
    align-items: center;
    padding: 5rem 1.5rem;
    gap: 1rem;
}

.waitlist__left-section{
    background-color: #000000;
    width: 100%;
}

.waitlist__right-section{
    width: 100%;
    padding: 0 5rem;
}

.waitlist__right-section-wrapper{
    background-color: #ffffff;
    color: #284154;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0;
    border-radius: 15px;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.waitlist__form{
    width: 100%;
    padding: 0 4rem;
}

.waitlist__form-heading{
    padding: 0 0 1.5rem 0;
}

.waitlist__heading-text{
    font-size: 30px;
    font-weight: 600;
    text-align: center;
}

.waitlist__subheading-text{
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.5px;
    text-align: center;
    padding: 0.5rem 0 0.25rem 0;
}

.waitlist__name-input-wrapper{
    display: flex;
    gap: 2rem;
    width: 100%;
}

.waitlist__input{
    width: 100%;
    padding: 0.5rem;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 0.10rem solid #CDCDCD;
    font-family: 'PP Neue Montreal', sans-serif;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.75px;
    color: #4d708a;
    outline: none;
}

.waitlist__input::placeholder{
    color: #878c91;
    font-family: 'PP Neue Montreal', sans-serif;
    font-size: 15px;
    font-weight: 450;
    letter-spacing: 0.5px;
}

.waitlist__email-input-wrapper{
    padding-top: 1.25rem;
}

.waitlist__phone-number-input-wrapper{
    padding-top: 1.25rem;
}

.waitlist__referral-code-input-wrapper{
    padding-top: 1.25rem;
}

.waitlist__agreement-input-wrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1.25rem 0.25rem 0 0.25rem;
    gap: 0.75rem;
}

.waitlist__policy-input{
    width: 5%;
    margin: 0;
    padding: 0;
}

.waitlist__policy-text{
    width: 100%;
}

.waitlist__policy-text-content{
    text-align: left;
    font-size: 13px;
    font-weight: 450;
    color: #a3a1a1;
    letter-spacing: 0.5px;
}

.waitlist__button-wrapper{
    padding: 2rem 0 1rem 0;
}

.waitlist__submit-button{
    width: 100%;
    padding: 0.75rem 1.25rem;
    font-family: 'PP Neue Montreal', sans-serif;
    font-size: 16px;
    font-weight: 450;
    letter-spacing: 1px;
    color: #ffffff;
    background-color: #b82142;
    border: 0.1px solid #b82142;
    border-radius: 0.15rem;
    cursor: pointer;
}

.waitlist__submit-button:hover{
    border: 0.1px solid #ff5161;
    background-color: #ff5161;
}