
@font-face {
     font-family: 'PP Neue Montreal';
     src: url('./PPNeueMontreal/PPNeueMontreal-Thin.otf') format('opentype');
     font-weight: 200;
     font-style: normal;
}

@font-face {
     font-family: 'PP Neue Montreal';
     src: url('./PPNeueMontreal/PPNeueMontreal-Book.otf') format('opentype');
     font-weight: 400;
     font-style: normal;
}

@font-face {
     font-family: 'PP Neue Montreal';
     src: url('./PPNeueMontreal/PPNeueMontreal-Regular.otf') format('opentype');
     font-weight: 450;
     font-style: normal;
}

@font-face {
     font-family: 'PP Neue Montreal';
     src: url('./PPNeueMontreal/PPNeueMontreal-Medium.otf') format('opentype');
     font-weight: 530;
     font-style: normal;
}

@font-face {
     font-family: 'PP Neue Montreal';
     src: url('./PPNeueMontreal/PPNeueMontreal-Bold.otf') format('opentype');
     font-weight: 800;
     font-style: normal;
}


@font-face {
     font-family: 'PP Neue Montreal';
     src: url('./PPNeueMontreal/PPNeueMontreal-Italic.otf') format('opentype');
     font-weight: 450;
     font-style: italic;
}


@font-face {
     font-family: 'PP Neue Montreal';
     src: url('./PPNeueMontreal/PPNeueMontreal-SemiBolditalic.otf') format('opentype');
     font-weight: 700;
     font-style: italic;
}