.whitelist{
    margin: auto;
}

.whitelist .page-container{
    max-width: 1280px;
    margin: auto;
    align-items: center;
    padding: 1rem 2.5rem 5rem;
    background-color: #F0F2F5 ;
}

.whitelist .page-container .title{
    margin: auto;
    align-items: center;
    max-width: 1200px;
}

.whitelist .page-container .title p{
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 65px;
    color: #3a3535;
    line-height: 70px;
}

.whitelist .page-container .title p:nth-child(2){
    padding-top: 30px;
    text-align: center;
    font-family: 'Figtree', sans-serif;
    font-weight: 300;
    font-size: 35px;
    color: #424040;
}

.whitelist .page-container .form{
    padding-top: 10px;
    margin: auto;
    align-items: center;
}
.whitelist .page-container .form form{
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 7px;
}

.whitelist .page-container .form input{
    padding: 10px;
    width: 23%;
    font-weight: 400;
    font-size: 18px;
    border: 0.12rem solid #777b8d;
}

.whitelist .page-container .form input:focus{
    border: 0.12rem solid #777b8d;
}

.whitelist .page-container .form input::placeholder{
    font-family: 'Figtree', sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: rgb(156,172,203);
}

.whitelist .page-container .form button{
    padding: 10px 15px;
    font-family: 'Figtree', sans-serif;
    font-weight: 600;
    font-size: 18px;
    background-color: #1F4168;
    color: white;
    border: none;
    cursor: pointer;
}

.whitelist .page-container .form button:hover{
    background-color: #34567e;
}

.whitelist .page-container .form .confirmation-message {
    text-align: center;
    font-family: 'Figtree', sans-serif;
    font-weight: 600;
    font-size: 25px;
    margin-top: 1rem;
    color: green;
}